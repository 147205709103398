import type { Locale } from '@/entities/routerBridge';
import { SduiApiVersion, SduiBffEnv } from '@/shared/config';
import { PageLoader } from '@ldfeplatform/drx-component-library.ui.atoms.page-loader';
import { useRouter } from 'next/router';
import { lazy, useEffect, useState, type ComponentType, type FC } from 'react';
import { trackRender } from '../context';
import type { ViewDefinition } from '../types';
import { Datepicker } from './datepicker';

export type ViewResolverDigitalHealthProps = {
	locale: Locale;
	/** The page id for which data needs to be resolved */
	pageId: string;
	/** The view definition of the SDUI page */
	initialData?: ViewDefinition | null;
	/** The theme token for the SDUI page */
	themeTokens?: Record<string, string> | null;
	requestOptions: RequestInit;
};

export const ViewResolverDigitalHealth: FC<ViewResolverDigitalHealthProps> = ({
	locale,
	initialData,
	themeTokens,
	pageId,
	requestOptions,
	...rest
}) => {
	const [ViewResolverComponent, setViewResolverComponent] = useState<ComponentType<any> | null>(null);
	const [ViewResolverSection, setViewResolverSection] = useState<ComponentType<any> | null>(null);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			setViewResolverComponent(lazy(() => import('ldpViewResolverSdm/Resolver')));
			setViewResolverSection(lazy(() => import('ldpViewResolverSdm/Section')));
		}
	}, []);

	const router = useRouter();
	const { preview: isContentfulPreview = 'false', dateTime } = router.query as { preview: string; dateTime?: string };

	// if SSR call fails, try fetching data client-side by passing `undefined`
	const queryConfig = initialData
		? {
				// The initial data will be used to populate the cache, could be SSR fetched
				initialData,
				// Setting staleTime to Infinity will prevent the data from being refetched
				staleTime: Infinity,
		  }
		: undefined;
	return (
		<>
			{ViewResolverComponent ? (
				<ViewResolverComponent
					locale={locale}
					pageId={pageId}
					env={SduiBffEnv}
					apiVersion={SduiApiVersion}
					themeTokens={themeTokens}
					queryConfig={queryConfig}
					commonContexts={{
						analytics: {
							trackEvent: () => null,
							trackRender,
						},
						settings: {
							locale,
							baseFont: 16,
							designTokenPlatform: 'web-16',
						},
					}}
					fetchOptions={requestOptions}
					{...rest}
				>
					{ViewResolverSection ? (
						<ViewResolverSection apiVersion={SduiApiVersion} layoutSection="mainContentCollection" />
					) : (
						<PageLoader />
					)}
				</ViewResolverComponent>
			) : (
				<PageLoader />
			)}
			{isContentfulPreview === 'true' ? <Datepicker dateTime={dateTime} /> : null}
		</>
	);
};
